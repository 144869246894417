<template>
  <div v-if="cmsSite">
    <!-- <b-overlay :show="isUpdatingCmsSite">
      <b-card class="mb-4">
        <h5 class="text-primary">Configurations</h5>
        <b-form-checkbox
          v-model="configuration.isRemapTypes"
          switch
          size="lg"
          @change="onToggleTypeRemapping"
          >Enable type remapping</b-form-checkbox
        >
      </b-card>
    </b-overlay> -->
    <b-card class="mb-4">
      <h5 class="text-primary">Game covers</h5>
      <CmsGameCover />
    </b-card>
    <!-- <b-card v-if="configuration.isRemapTypes" class="mb-4">
      <h5 class="text-primary">Type Remap</h5>
      <CmsTypeRemap />
    </b-card> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    CmsGameCover: () => import('./game-cover'),
    // CmsTypeRemap: () => import('./type-remap'),
  },
  data() {
    return {
      configuration: {
        isRemapTypes: false,
      },
    }
  },
  computed: {
    ...mapState({
      cmsSite: (state) => state.cms.cmsSite,
      isUpdatingCmsSite: (state) => state.cms.isUpdatingCmsSite,
    }),
  },
  watch: {
    cmsSite(val) {
      if (val) {
        this.configuration = JSON.parse(JSON.stringify(val))
      }
    },
  },
  mounted() {
    this.configuration = JSON.parse(JSON.stringify(this.cmsSite))
  },
  methods: {
    ...mapActions(['updateCmsSite']),
    async onToggleTypeRemapping() {
      const data = {
        ...this.configuration,
        isRemapTypes: this.configuration.isRemapTypes,
      }
      await this.updateCmsSite({ siteId: this.configuration.id, data })
    },
  },
}
</script>
